import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { theme } from '@theme';

export type IAspectRatio = '3-1' | '2-1' | '1-1' | '16-9';

type AspectRatioProps = {
    ratio?: IAspectRatio;
    containImage?: boolean;
};

const StyledRatio = styled.div<{ $containImage: boolean }>`
    display: block;
    position: relative;
    overflow: hidden;
    width: 100%;
    background: ${theme.color.greyLight};

    &:before {
        width: 1px;
        margin-left: -1px;
        float: left;
        height: 0;
        padding-top: 56.25%;
        content: '';
    }

    &.ratio-3-1:before {
        padding-top: 33.33%;
    }

    &.ratio-2-1:before {
        padding-top: 50%;
    }

    &.ratio-1-1:before {
        padding-top: 100%;
    }

    &.ratio-16-9:before {
        padding-top: 56.25%;
    }

    &:after {
        display: table;
        clear: both;
    }

    & > :first-child {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    ${({ $containImage }) => {
        if ($containImage) {
            return `
                background-color: transparent;
                img {
                    object-fit: contain;
                }
            `;
        }
    }}
`;

export const AspectRatio = ({
    children,
    ratio = '16-9',
    containImage = false,
}: PropsWithChildren<AspectRatioProps>) => {
    return (
        <StyledRatio className={`ratio-${ratio}`} $containImage={containImage}>
            {children}
        </StyledRatio>
    );
};

const slash = '/';

export const trimSlashes = (url: string) => {
    if (url.startsWith(slash)) {
        url = url.substring(1);
    }
    if (url.endsWith(slash)) {
        url = url.slice(0, -1);
    }
    return url;
};

export const absolutePath = (url: string) => {
    if (!url.startsWith(slash)) {
        url = `/${url}`;
    }
    if (!url.endsWith(slash)) {
        url = `${url}/`;
    }
    return url;
};

export const getFileExtension = (url: string) => {
    return url.split('.')?.pop()?.split(/#|\?/)[0];
}

import styled from 'styled-components';
import { theme } from '@theme';

export const PrimaryButton = styled.button`
    background-color: ${theme.color.primary};
    color: ${theme.color.white};
    border: none;
    padding: ${theme.space[0]} ${theme.space[1]};
    border-radius: ${theme.borderRadius};
    cursor: pointer;
    display: flex;
    gap: ${theme.space[0]};
    align-items: center;

    &:disabled {
        background-color: ${theme.color.primary}80;
    }
`;

import React, { PropsWithChildren } from 'react';
import { theme } from '@theme';
import styled from 'styled-components';

type StackProps = {
    size?: number;
};

const StyledStack = styled.div<{ $size: number }>`
    display: flex;
    flex-direction: column;

    & > * + * {
        margin-top: ${({ $size }) => theme.space[$size]};
    }
`;

export const Stack = ({ children, size = 0 }: PropsWithChildren<StackProps>) => {
    return <StyledStack $size={size}>{children}</StyledStack>;
};

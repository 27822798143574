import React, { useEffect, useState, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { globalHistory } from '@reach/router';
import styled from 'styled-components';
import theme, { StyledHasIcon, StyledIcon } from '@theme';

import Link from '@components/link';
import { TNavLink, StyledNavItem } from './navigationItem';
import { MdMenu, MdArrowRightAlt } from 'react-icons/md';

const animationDuration = 200;

type Props = {
    currentUrl: string;
    navLinks: TNavLink[];
};

type clickHandler = (navLink: TNavLink) => void;

const StyledMobileOverlay = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: ${theme.color.white};
    left: 0;
    top: 0;
    transform: translateY(-100%);
    transition: 0.2s;
    z-index: 10;
    padding-top: ${theme.headerHeight * 2}rem;
    box-sizing: border-box;

    &.active {
        transform: translateY(0);
    }
`;
const StyledOverlayInner = styled.div`
    border-top: 1px solid ${theme.color.grey};
    position: relative;
    height: 100%;
    overflow-y: scroll;
`;

const StyledLinkList = styled.div`
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    padding: ${theme.space[1]};

    ul {
        display: flex;
        flex-direction: column;
    }
`;

const StyledMobileMenu = styled.div`
    display: flex;
    align-items: center;
    font-size: 2rem;
    z-index: 15;
    cursor: pointer;
`;

const StyledBackIcon = styled(StyledIcon)`
    padding: ${theme.space[0]};
    transform: rotate(180deg);
`;

const StyledTransition = styled.div`
    top: 0;
    left: 0;
    bottom: 0;
    position: absolute;
    width: 100%;
    background-color: ${theme.color.white};

    &:empty {
        display: none;
        left: 100%;
    }
`;

const StyledMobileNavContainer = styled.div`
    display: flex;

    *[class*="linklist"] {
        transition: transform ${animationDuration}ms ease-in;
    }

    .linklist-enter, 
    .linklist-appear {
        transform: translateX(100%);
    }
    
    .linklist-enter.linklist-enter-active {
        transform: translateX(0%);
    }
    
    .linklist-enter-done {
        transform: translateX(0%);
    }

    .linklist-exit-active {
        transform: translateX(100%);
    }

    .linklist-exit-done {
        display: none;
    }

    .linklist-exit-active, 
    .linklist-enter-active {
        border-left: 1px solid ${theme.color.grey}
        box-shadow: -4px 0px 4px -3px ${theme.color.grey};
        -webkit-box-shadow: -4px 0px 4px -3px ${theme.color.grey};
        -moz-box-shadow: -4px 0px 4px -3px ${theme.color.grey};
    }

    @media (min-width: ${theme.breakpoints[2]}rem) {
        display: none;
    }
`;

const NavItem = ({
    navLink,
    currentUrl,
    clickShowHandler,
}: {
    navLink: TNavLink;
    currentUrl: string;
    clickShowHandler: clickHandler;
}) => {
    const { label, url, children } = navLink;
    const hasChildren = children && children.length;
    if (hasChildren) {
        return (
            <StyledHasIcon onClick={() => clickShowHandler(navLink)}>
                <span>{label}</span>
                <StyledIcon>
                    <MdArrowRightAlt />
                </StyledIcon>
            </StyledHasIcon>
        );
    } else if (url === currentUrl) {
        return <span className="active">{label}</span>;
    } else if (!url) {
        return <span>{label}</span>;
    } else {
        return <Link url={url}>{label}</Link>;
    }
};

export const LinkList = ({
    clickHideHandler,
    currentUrl,
    navLinks,
}: {
    clickHideHandler?: () => void;
    currentUrl: string;
    navLinks: TNavLink[];
}) => {
    const [children, setChildren] = useState<TNavLink[] | undefined>(undefined);
    const [childrenAnimatingOut, setChildrenAnimatingOut] = useState<boolean>(false);

    const childrenRef = useRef(null);

    const backColum = clickHideHandler ? (
        <div>
            <StyledBackIcon onClick={() => clickHideHandler()}>
                <MdArrowRightAlt />
            </StyledBackIcon>
        </div>
    ) : null;

    const clickShowHandler = (item: TNavLink) => {
        if (item.children && item.children.length && !childrenAnimatingOut) {
            const items = [...item.children];
            if (item.url) {
                const clone = { ...item, children: undefined };
                items.unshift(clone);
            }
            setChildren(items);
        }
    };

    const hideHandler = () => {
        setChildrenAnimatingOut(true);
        setTimeout(() => {
            setChildrenAnimatingOut(false);
            setChildren(undefined);
        }, animationDuration);
    };

    const childList = children ? (
        <LinkList clickHideHandler={hideHandler} currentUrl={currentUrl} navLinks={children} />
    ) : null;

    return (
        <>
            <StyledLinkList>
                {backColum}
                <ul>
                    {navLinks.map((navLink) => (
                        <StyledNavItem key={`mobile-${navLink.id}`}>
                            <NavItem currentUrl={currentUrl} navLink={navLink} clickShowHandler={clickShowHandler} />
                        </StyledNavItem>
                    ))}
                </ul>
            </StyledLinkList>
            <CSSTransition
                nodeRef={childrenRef}
                in={Boolean(children) && !childrenAnimatingOut}
                timeout={animationDuration}
                classNames="linklist"
            >
                <StyledTransition ref={childrenRef}>{childList}</StyledTransition>
            </CSSTransition>
        </>
    );
};

export const MobileNavigation = ({ currentUrl, navLinks }: Props) => {
    const [active, setActive] = useState(false);

    useEffect(() => {
        return globalHistory.listen(({ action }) => {
            if (action === 'PUSH') setActive(false);
        });
    }, [active, setActive]);

    return (
        <StyledMobileNavContainer>
            <StyledMobileOverlay className={active ? 'active' : ''}>
                <StyledOverlayInner>
                    <div>
                        <LinkList currentUrl={currentUrl} navLinks={navLinks} />
                    </div>
                </StyledOverlayInner>
            </StyledMobileOverlay>
            <StyledMobileMenu onClick={() => setActive(!active)}>{<MdMenu />}</StyledMobileMenu>
        </StyledMobileNavContainer>
    );
};

export const i18n = {
    controls: {
        more: 'Mehr',
    },
    news: {
        title: 'News',
        empty: 'Keine Newsbeiträge gefunden',
    },
    index: {
        mainSponsors: "Hauptsponsoren",
    },
    team: {
        empty: 'Keine Teams gefunden',
        gamePlan: 'Spielplan',
        links: 'Links',
        players: 'Spieler',
        results: 'Resultate & Rangliste',
        sponsors: 'Sponsoren',
        table: 'Tabelle',
    },
    jobs: {
        title: 'Jobs',
        empty: 'Aktuell gibt es keine offenen Jobs.',
    },
    social: {
        follow: 'Follow us',
    },
    sportsGround: {
        title: 'Sportplätze',
        empty: 'Keine Sportplätze gefunden',
    },
};

import styled from 'styled-components';
import theme from '@theme';

export type TNavLink = {
    id: string;
    label: string;
    url: string | false;
    highlight?: boolean;
    children?: TNavLink[];
};

export type TNavigationItem = { currentUrl: string, topLevel?: boolean } & TNavLink;

export const StyledNavItem = styled.li`
    list-style: none;
    text-transform: uppercase;
    letter-spacing: 0.075rem;
    font-weight: 300;
    font-size: ${theme.smallFontSize};
    display: flex;
    position: relative;

    & > span,
    & > a {
        padding: ${theme.space[0]} ${theme.space[1]};
    }
`;

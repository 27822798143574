import React from 'react';
import styled from 'styled-components';
import theme, { StyledHasIcon, StyledIcon } from '@theme';
import { absolutePath } from '@utils';

import Link from '@components/link';
import { TNavigationItem, TNavLink, StyledNavItem } from './navigationItem';
import { MdExpandMore } from 'react-icons/md';

type Props = {
    currentUrl: string;
    navLinks: TNavLink[];
};

const StyledDropdown = styled.ul`
    display: none;
    flex-direction: column;
    position: absolute;
    background-color: ${theme.color.white};
    bottom: 0;
    transform: translateY(100%);
    min-width: 100%;
`;

const StyledDesktopNavItem = styled(StyledNavItem)`
    white-space: nowrap;

    &:hover > ${StyledDropdown} {
        display: flex;
    }
`;

export const StyledDesktopNav = styled.nav`
    & > ul {
        display: flex;
        & > li {
            & > a,
            & > span {
                display: flex;
                align-items: center;
            }
        }
    }

    ${StyledDropdown} {
        ${StyledIcon} {
            transform: rotate(-90deg);
        }

        ${StyledDropdown} {
            right: 0;
            top: 0;
            bottom: unset;
            transform: translateX(100%);
        }
    }
`;

const NavigationItem = ({ url, label, children, currentUrl, highlight }: TNavigationItem) => {
    const isStatic = !url || currentUrl === absolutePath(url);
    const hasChildren = children && children.length;
    const labelTag = hasChildren ? (
        <StyledHasIcon>
            {label}
            <StyledIcon>
                <MdExpandMore />
            </StyledIcon>
        </StyledHasIcon>
    ) : (
        label
    );
    return (
        <StyledDesktopNavItem>
            {isStatic ? (
                <span>{labelTag}</span>
            ) : (
                <Link url={url} highlight={highlight}>
                    {labelTag}
                </Link>
            )}
            {hasChildren ? (
                <StyledDropdown>
                    {children.map((navItem) => (
                        <NavigationItem key={navItem.label} currentUrl={currentUrl} {...navItem} />
                    ))}
                </StyledDropdown>
            ) : null}
        </StyledDesktopNavItem>
    );
};

export const DesktopNavigation = ({ currentUrl, navLinks }: Props) => {
    return (
        <StyledDesktopNav>
            <ul>
                {navLinks.map((navItem) => (
                    <NavigationItem key={navItem.label} currentUrl={currentUrl} {...navItem} />
                ))}
            </ul>
        </StyledDesktopNav>
    );
};

const color = {
    primary: '#1259a9',
    primaryLight: '#1C86FF',
    primaryDark: '#0A305C',
    white: '#FFF',
    textColor: '#000',
    grey: '#DADADA',
    greyLight: '#FAFAFA',
    greyDark: '#7E7E7E',
    success: '#94cb86',
};


export const theme = {
    borderRadius: '0.25rem',
    color: color,
    space: ['0.75rem', '1.5rem', '3rem', '5rem'],
    minBoxSize2Col: '20rem',
    minBoxSize3Col: '14rem',
    smallFontSize: '0.85rem',
    trim: ['90rem', '60rem', '50rem', '40rem'],
    headerHeight: 3.5,
    breakpoints: [30, 48, 64, 80],
};

export const remValue = (value: number) => `${value}rem`;

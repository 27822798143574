import styled from 'styled-components';
import { theme } from '@theme';
import { msFontSize } from './modularScale';

export const Capitalized = styled.div`
    text-transform: uppercase;
    letter-spacing: 0.05rem;
    color: ${theme.color.primary};
    font-size: ${msFontSize(-0.5)};
`;

export const PageTitle = styled.h1`
    & + * {
        margin-top: ${msFontSize(1.5)};
    }

    * + & {
        margin-bottom: ${msFontSize(2)};
    }
`;

export const SubTitle = styled.h2`
    & + * {
        margin-top: ${msFontSize(1)};
    }

    * + & {
        margin-top: ${msFontSize(2)};
    }
`;

export const Heading3 = styled.h3`
    font-size: ${msFontSize(0.5)};

    & + * {
        margin-top: ${msFontSize(1)};
    }
`;


export const Heading4 = styled.h4`
    font-size: ${msFontSize(0)};

    & + * {
        margin-top: ${msFontSize(0)};
    }
`;

import { TGenericPage, TPageMeta } from '@common';

export const defaultHelmet = (meta: TPageMeta) => {
    return { title: meta.siteTitle, description: meta.siteDescription };
};

export const customHelmet = (meta: TPageMeta, { title, description }: { title?: string; description?: string }) => {
    const defHelmet = defaultHelmet(meta);
    return {
        title: title ? `${title} | ${meta.siteTitle}` : defHelmet.title,
        description: description || defHelmet.description,
    };
};

export const genericPageHelmet = (meta: TPageMeta, page: TGenericPage) => {
    return customHelmet(meta, { title: page.title, description: page.description });
};

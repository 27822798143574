import React from 'react';
import styled from 'styled-components';
import theme from '@theme';

import { MobileNavigation } from './mobileNavigation';
import { DesktopNavigation, StyledDesktopNav } from './desktopNavigation';
import { TNavLink } from './navigationItem';

type Props = { currentUrl: string; navLinks: TNavLink[] };

const StyledNav = styled.div`
    display: flex;

    ${StyledDesktopNav} {
        display: none;
    }

    @media (min-width: ${theme.breakpoints[2]}rem) {
        ${StyledDesktopNav} {
            display: flex;
        }
    }
`;

export const Navigation = ({ currentUrl, navLinks }: Props) => {
    return (
        <StyledNav>
            <DesktopNavigation currentUrl={currentUrl} navLinks={navLinks} />
            <MobileNavigation currentUrl={currentUrl} navLinks={navLinks} />
        </StyledNav>
    );
};

import React, { PropsWithChildren } from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { absolutePath } from '@utils';
import { ArrowRight, ArrowLeft } from '@phosphor-icons/react';
import styled from 'styled-components';
import theme, { msFontSize } from '@theme';

const BUTTON_ICON_WEIGHT = 'light';

type LinkProps = {
    url: string;
    highlight?: boolean;
    buttonStyle?: 'primary';
};

const isExternal = (url: string) => url.startsWith('http://') || url.startsWith('https://');

export const StyledLinkWithIcon = styled(GatsbyLink)`
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    gap: ${theme.space[0]};

    svg {
        transition: transform 0.3s;
    }
`;

const StyledButton = styled(StyledLinkWithIcon)`
    background-color: ${theme.color.white};
    border: 1px solid ${theme.color.grey};
    border-radius: ${theme.borderRadius};
    color: ${theme.color.textColor};

    box-shadow: rgba(213, 217, 217, 0.5) 0 2px 5px 0;
    box-sizing: border-box;
    cursor: pointer;
    font-size: ${msFontSize(-0.5)};
    letter-spacing: 0.05em;
    line-height: 2.2;
    padding: 0 ${theme.space[1]} 0 ${theme.space[1]};
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    min-width: 100px;

    &:hover {
        background-color: #f7f7fa;
    }

    &:focus {
        border-color: ${theme.color.primary};
        box-shadow: rgba(213, 217, 217, 0.5) 0 2px 5px 0;
        outline: 0;
    }
`;

const Link = ({ buttonStyle, children, highlight, url }: PropsWithChildren<LinkProps>) => {
    let classname = buttonStyle ? `btn ${buttonStyle}` : undefined;
    classname = (highlight ? `${classname} bold` : classname)?.trim();
    if (isExternal(url)) {
        return (
            <a className={classname} href={url} target="_blank" rel="noreferrer">
                {children}
            </a>
        );
    } else {
        return (
            <GatsbyLink className={classname} to={absolutePath(url)}>
                {children}
            </GatsbyLink>
        );
    }
};

type ButtonProps = {
    url: string;
    icon?: 'arrow-right' | 'arrow-left';
    style?: 'primary';
};

export const Button = ({ children, icon, url }: PropsWithChildren<ButtonProps>) => {
    url = isExternal(url) ? url : absolutePath(url);
    let iconComponent = null;

    if (icon) {
        switch (icon) {
            case 'arrow-right':
                iconComponent = <ArrowRight weight={BUTTON_ICON_WEIGHT} />;
                break;
            case 'arrow-left':
                iconComponent = <ArrowLeft weight={BUTTON_ICON_WEIGHT} />;
                break;
        }
    }
    return (
        <StyledButton to={url}>
            {iconComponent}
            {children}
        </StyledButton>
    );
};

export default Link;

import styled from 'styled-components';
import { theme } from '@theme';

export const StyledIcon = styled.i`
    display: inline-flex;
    align-items: center;
`;

export const StyledHasIcon = styled.span`
    display: inline-flex;
    gap: ${theme.space[0]};
`;

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-detail-generic-page-tsx": () => import("./../../../src/templates/detail/genericPage.tsx" /* webpackChunkName: "component---src-templates-detail-generic-page-tsx" */),
  "component---src-templates-detail-job-tsx": () => import("./../../../src/templates/detail/job.tsx" /* webpackChunkName: "component---src-templates-detail-job-tsx" */),
  "component---src-templates-detail-news-tsx": () => import("./../../../src/templates/detail/news.tsx" /* webpackChunkName: "component---src-templates-detail-news-tsx" */),
  "component---src-templates-detail-sports-ground-tsx": () => import("./../../../src/templates/detail/sportsGround.tsx" /* webpackChunkName: "component---src-templates-detail-sports-ground-tsx" */),
  "component---src-templates-detail-team-tsx": () => import("./../../../src/templates/detail/team.tsx" /* webpackChunkName: "component---src-templates-detail-team-tsx" */),
  "component---src-templates-jobs-tsx": () => import("./../../../src/templates/jobs.tsx" /* webpackChunkName: "component---src-templates-jobs-tsx" */),
  "component---src-templates-news-tsx": () => import("./../../../src/templates/news.tsx" /* webpackChunkName: "component---src-templates-news-tsx" */),
  "component---src-templates-sponsors-tsx": () => import("./../../../src/templates/sponsors.tsx" /* webpackChunkName: "component---src-templates-sponsors-tsx" */),
  "component---src-templates-sports-grounds-tsx": () => import("./../../../src/templates/sportsGrounds.tsx" /* webpackChunkName: "component---src-templates-sports-grounds-tsx" */),
  "component---src-templates-teams-tsx": () => import("./../../../src/templates/teams.tsx" /* webpackChunkName: "component---src-templates-teams-tsx" */)
}


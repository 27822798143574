import { createGlobalStyle } from 'styled-components';
import { normalize } from './normalize';
import { msFontSize } from './modularScale';
import { theme } from '@theme';

export const GlobalStyle = createGlobalStyle`
  ${normalize}
  
  * {
    box-sizing: border-box;
  }

  html {
    font-size: 100%;
    color: ${theme.color.textColor};
  }

  body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 300;
    line-height: 1.4;
    min-height: 100vh;
  }

  strong, 
  b, 
  .bold {
    font-weight: 600;
  }

  p, ul, h1, h2, h3, h4, h5, h6 {
    margin-block-start: 0;
    margin-block-end: 0;  
  }
  
  ul {
    padding-inline-start: unset;
  }

  h1 {
    font-size: ${msFontSize(3)}
  }

  h2 {
    font-size: ${msFontSize(2)}
  }

  h3 {
    font-size: ${msFontSize(1.5)}
  }

  a {
    color: ${theme.color.primary};
    text-decoration: none;
  }

  img {
    max-width: 100%;
  }

  @media (min-width: ${theme.breakpoints[2]}rem) {
    html {
      font-size: 125%;
    }  
  }
`;

import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import theme, { StyledHasIcon, StyledIcon, Trimmed } from '@theme';
import { i18n, TPageMeta, TContentfulPageLink } from '@common';
import { FaInstagram } from 'react-icons/fa6';
import { FaFacebook } from 'react-icons/fa6';

import Link from '@components/link';
import RichText from '@components/richText';

type Props = {
    currentUrl: string;
    pageMeta: TPageMeta;
};

const useSecondaryNavQuery = (): TContentfulPageLink[] => {
    const meta = useStaticQuery(graphql`
        {
            allContentfulPage(filter: { secondaryNavigation: { eq: true } }) {
                nodes {
                    ...ContentfulPageLink
                }
            }
        }
    `);
    return meta.allContentfulPage.nodes;
};

const StyledFooter = styled.footer`
    border-top: 1px solid ${theme.color.grey};
    padding: ${theme.space[1]} 0;
    margin-top: auto;
`;

const StyledGrid = styled.div`
    display: grid;
    grid-gap: ${theme.space[1]};
    grid-template-columns: repeat(auto-fill, minmax(${theme.minBoxSize3Col}, 1fr));

    h4 {
        margin-bottom: ${theme.space[0]};
    }
`;

const NavItem = ({ currentUrl, url, label }: { currentUrl: string; url?: string; label: string }) => {
    if (url === currentUrl) {
        return <span className="active">{label}</span>;
    } else if (!url) {
        return <span>{label}</span>;
    } else {
        return <Link url={url}>{label}</Link>;
    }
};

const StyledLinkList = styled.ul`
    list-style: none;
`;

export const Footer = ({ currentUrl, pageMeta }: Props) => {
    const { siteTitle, address, instagramUrl, facebookUrl } = pageMeta;
    const socialUrls = [
        { url: instagramUrl, label: 'Instagram', icon: <FaInstagram /> },
        { url: facebookUrl, label: 'Facebook', icon: <FaFacebook /> },
    ].filter(({ url }) => Boolean(url));
    const secondaryNav = useSecondaryNavQuery();
    return (
        <StyledFooter>
            <Trimmed trim={1}>
                <StyledGrid>
                    <div>
                        <h4>{siteTitle}</h4>
                        <RichText text={address} />
                    </div>
                    <div>
                        <StyledLinkList>
                            {secondaryNav.map(({ id, url, title }) => {
                                return (
                                    <li key={id}>
                                        <NavItem currentUrl={currentUrl} url={url} label={title} />
                                    </li>
                                );
                            })}
                        </StyledLinkList>
                    </div>
                    <div>
                        {socialUrls.length ? (
                            <>
                                <h4>{i18n.social.follow}</h4>
                                <StyledLinkList>
                                    {socialUrls.map(({ url, label, icon }) => {
                                        return (
                                            <li key={url}>
                                                <a href={url} target="_blank" rel="noreferrer">
                                                    <StyledHasIcon>
                                                        <span>{label}</span>
                                                        <StyledIcon>{icon}</StyledIcon>
                                                    </StyledHasIcon>
                                                </a>
                                            </li>
                                        );
                                    })}
                                </StyledLinkList>
                            </>
                        ) : null}
                    </div>
                </StyledGrid>
            </Trimmed>
        </StyledFooter>
    );
};

import React, { ReactNode } from 'react';
import styled from 'styled-components';
import theme, { msFontSize } from '@theme';
import { TRichText } from '@common';
import { absolutePath, getFileExtension } from '@utils';

import { Link } from 'gatsby';
import { Options } from '@contentful/rich-text-react-renderer';
import { BLOCKS, Block, INLINES, Inline } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';

import { FaRegFilePdf, FaRegFileWord, FaRegFileExcel, FaRegFile } from "react-icons/fa";
import { StyledLinkWithIcon } from '@components/link';

type Props = {
    text: TRichText;
};

const StyledRichText = styled.div`
    * + p,
    * + ul,
    * * table,
    p + *,
    ul + *,
    table + * {
        margin-top: ${msFontSize(0.5)};
    }

    * + h3,
    h2 + * {
        margin-top: ${msFontSize(1)};
    }

    * + h2,
    h1 + * {
        margin-top: ${msFontSize(1.5)};
    }

    * + h1 {
        margin-top: ${msFontSize(2)};
    }

    h3 + * {
        margin-top: ${msFontSize(0)};
    }

    * + h3,
    h2 + * {
        margin-top: ${msFontSize(1)};
    }

    * + h2,
    h1 + * {
        margin-top: ${msFontSize(1.5)};
    }

    * + h1 {
        margin-top: ${msFontSize(2)};
    }

    a {
        text-decoration: underline;
    }

    a:visited,
    a:hover {
        color: ${theme.color.primaryDark};
    }

    table {
        border-collapse: collapse;
        td {
            padding: ${theme.space[0]};
            border-bottom: 1px solid ${theme.color.grey};
            vertical-align: top;
        }

        tr:last-child td {
            border-width: 0;
        }

        @media (max-width: ${theme.breakpoints[0]}rem) {
            td:first-child {
                padding-left: 0;
            }
            td:last-child {
                padding-right: 0;
            }
        }
    }
`;

const assetIcon = (url: string) => {
    const extension = getFileExtension(url);
    if (!extension) {
        return null
    };
    switch (extension) {
        case 'pdf':
            return <FaRegFilePdf />;
        case 'doc':
        case 'docx':
            return <FaRegFileWord />;
        case 'xls':
        case 'xlsx':
            return <FaRegFileExcel />;
        default:
            return <FaRegFile />;
    }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isIterableReactNode(value: any): value is Iterable<React.ReactNode> {
    return value != null && typeof value[Symbol.iterator] === 'function';
}

const options: Options = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (_: Block | Inline, children: ReactNode) => {
            // Replace \n with <br /> inside paragraphs
            if (children && isIterableReactNode(children))
                return (
                    <p>
                        {[...children].map((child) => {
                            if (typeof child === 'string') {
                                return child.split('\n').map((line, i) => (
                                    <React.Fragment key={i}>
                                        {i > 0 && <br />}
                                        {line}
                                    </React.Fragment>
                                ));
                            } else {
                                return child;
                            }
                        })}
                    </p>
                );
            return children;
        },
        [INLINES.ASSET_HYPERLINK]: (node: Block | Inline, children: ReactNode) => {
            const url = node.data.target?.file?.url;
            return (
                <StyledLinkWithIcon to={url || ''} target="_blank" rel="noopener noreferrer">
                    {assetIcon(url)}
                    {children}
                </StyledLinkWithIcon>

            );
        },
        [INLINES.ENTRY_HYPERLINK]: (node: Block | Inline, children: ReactNode) => {
            const url = node.data.target?.url;
            const absoluteUrl = url ? absolutePath(url) : undefined;
            return (
                <Link to={absoluteUrl || ''}>
                    {children}
                </Link>
            );
        },
    },
};

const RichText = ({ text }: Props) => <StyledRichText>{renderRichText(text, options)}</StyledRichText>

export default RichText;

import React, { PropsWithChildren } from 'react';
import { Helmet } from 'react-helmet';
import { TPageHelmet } from '@common';

type HelmetProps = {
    helmet: TPageHelmet;
};

const SiteHelmet = ({ helmet, children }: PropsWithChildren<HelmetProps>) => {
    const title = helmet.title;
    return (
        <Helmet title={title}>
            <meta name="description" content={helmet.description} />
            {children}
        </Helmet>
    );
};

export default SiteHelmet;

import { theme } from '@theme';
import styled from 'styled-components';

export const SafetyMargin = styled.div`
    box-sizing: border-box;
    padding-left: ${theme.space[1]};
    padding-right: ${theme.space[1]};
`;

export const Trimmed = styled(SafetyMargin)<{ trim: number }>`
    margin-left: auto;
    margin-right: auto;
    max-width: ${({ trim }) => theme.trim[trim]};
`;

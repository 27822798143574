import { TTeam, TWeblingGroup } from '@common';

export const teamUrl = (slug: string) => `teams/${slug}`;

export const normalizeTeam = (team: TWeblingGroup): TTeam => {
    return {
        id: team.id,
        slug: team.slug,
        title: team.properties.title,
        body: team.contentful?.body,
        fvrzTeamId: team.contentful?.fvrzTeamId,
        fubaWidgetId: team.contentful?.fubaWidgetId,
        image: team.contentful?.image,
        players: team.players,
        sponsors: team.contentful?.sponsors,
    };
};

import React from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import { PageProps } from 'gatsby';
import { useStaticQuery, graphql } from 'gatsby';
import theme, { GlobalStyle, msFontSize, StyledSection, Trimmed } from '@theme';
import { TPageMeta, TContentfulPageLink } from '@common';
import { Navigation } from './navigation';
import { Footer } from './footer';
import { TNavLink } from './navigationItem';
import { teamUrl, sportsGroundUrl, defaultHelmet } from '@utils';

import 'react-multi-carousel/lib/styles.css';
import SiteHelmet from '@components/siteHelmet';

type TTeamLink = {
    id: string;
    weblingId: number;
    slug: string;
    properties: {
        title: string;
    };
    subGroupIds?: string[];
    parentGroup?: {
        id: string;
    };
};

type NavData = {
    meta: TPageMeta;
    pages: TContentfulPageLink[];
    teams: TTeamLink[];
    sportsGrounds: TNavLink[];
};

const filterTeamLinks = (teams: TTeamLink[], comparer: (link: TTeamLink) => boolean) => {
    const init: { hit: TTeamLink[]; miss: TTeamLink[] } = { hit: [], miss: [] };
    return teams.reduce((acc, team) => {
        if (comparer(team)) {
            acc.hit.push(team);
        } else {
            acc.miss.push(team);
        }
        return acc;
    }, init);
};

const normalizeSportsGroundUrl = ({ id, name, slug }: { id: string, name: string, slug: string }): TNavLink => {
    return {
        id,
        label: name,
        url: sportsGroundUrl(slug),
    };

}

const makeTeamsTree = (teams: TTeamLink[]) => {
    const { hit: root, miss: rest } = filterTeamLinks(teams, (team) => !team.parentGroup);

    const findTeam = (teamId: string) => {
        return rest.find(({ weblingId }) => String(weblingId) === String(teamId));
    };

    const buildLinks = (current: TTeamLink): TNavLink => {
        const children = current.subGroupIds?.length
            ? current.subGroupIds.reduce((acc: TNavLink[], subGroupId: string) => {
                const team = findTeam(subGroupId);
                if (!team) {
                    return acc;
                }
                return [...acc, buildLinks(team)];
            }, [])
            : undefined;

        return {
            id: current.id,
            label: current.properties.title,
            url: children?.length && current.parentGroup ? false : teamUrl(current.slug),
            children,
        };
    };
    const teamsTree = buildLinks(root[0]);

    // Ignoring root page
    return teamsTree.children;
};

const makeNavigation = ({ meta, pages, teams, sportsGrounds }: NavData): TNavLink[] => {
    const childPages = pages.reduce((acc: { [key: string]: TContentfulPageLink }, page) => {
        acc[page.id] = page;
        return acc;
    }, {});

    const mainPages = meta.pages
        .map(({ id }) => pages.find((page) => page.id === id))
        .filter((page) => page !== undefined);

    const buildNavLink = ({ id, title, url, childPages: childrenIds }: TContentfulPageLink): TNavLink => {
        let children: TNavLink[] | undefined;
        if (childrenIds && childrenIds.length) {
            children = childrenIds
                .map(({ id }) => {
                    return buildNavLink(childPages[id]);
                })
                .filter(Boolean);
        }

        if (meta.teamsPage?.id === id) {
            const links: TNavLink[] | undefined = makeTeamsTree(teams);
            children = children ? children.concat(links || []) : links;
        }

        if (meta.sportsGroundsPage?.id === id) {
            children = sportsGrounds;
        }

        return {
            id: id,
            label: title,
            url: url || false,
            children: children,
        };
    };

    return mainPages.map(buildNavLink);
};

export const StyledMain = styled.main`
    h1,
    .h-1 {
        font-size: ${msFontSize(2)};
    }

    h2,
    .h-2 {
        font-size: ${msFontSize(1.5)};
    }

    h3,
    .h-3 {
        font-size: ${msFontSize(1)};
    }

    ul {
        padding-left: 2rem;
    }

    & > ${StyledSection}:first-child {
        padding-top: ${theme.space[3]};
    }
`;

const useNavQuery = (): NavData => {
    const { meta, pages, teams, sportsGrounds } = useStaticQuery(graphql`
        query PageLayout {
            meta: allContentfulPageMeta {
                nodes {
                    ...PageMeta
                }
            }
            pages: allContentfulPage {
                nodes {
                    ...ContentfulPageLink
                }
            }
            teams: allWeblingGroup(
                sort: { properties: { position: ASC } }
                filter: { contentfulTeam: { hidden: { ne: true } } }
            ) {
                nodes {
                    id
                    weblingId
                    slug
                    properties {
                        title
                    }
                    subGroupIds
                    parentGroup {
                        id
                    }
                }
            }
            sportsGrounds: allContentfulSportsGround(sort: { position: ASC }) {
                nodes {
                    id
                    name
                    slug
                }
            }
        }
    `);
    return {
        meta: meta.nodes[0],
        pages: pages.nodes,
        teams: teams.nodes,
        sportsGrounds: sportsGrounds.nodes.map(normalizeSportsGroundUrl)
    };
};

const StyledLogo = styled.div`
    position: relative;
    padding: ${theme.space[0]} 0;
    width: ${(theme.headerHeight - 0.75) * 2}rem;
    z-index: 15;

    a {
        position: absolute;
    }
`;

const StyledHeader = styled.header`
    z-index: 5;
    background-color: ${theme.color.white};
    box-sizing: border-box;
    border-bottom: 1px solid ${theme.color.grey};
`;

const StyledInnerHeader = styled(Trimmed)`
    display: flex;
    justify-content: space-between;
    position: relative;
    min-height: ${theme.headerHeight}rem;
`;

const StyledBody = styled.div`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
`;

const onlineShopNavLink = {
    id: 'online-shop-nav-item',
    label: 'Shop',
    highlight: true,
    url: 'https://boriol.ch/product-category/fc-oberrieden/',
};

export const Layout = (props: PageProps) => {
    const { path, children } = props;

    const navData = useNavQuery();
    const navLinks = [...makeNavigation(navData), onlineShopNavLink];

    // Using an anchor tag instead of a gatsby link is a WORKAROUND to force the react-multi-carousel to re-render
    const pathPrefix = process.env.GATSBY_PATH_PREFIX;
    const prefixedPath = `${pathPrefix || ''}/`;

    return (
        <>
            <SiteHelmet helmet={defaultHelmet(navData.meta)}>
                <link rel="manifest" href="/manifest.webmanifest" />
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link rel="preconnect" href="https://fonts.gstatic.com" />
                <link
                    href="https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,600;1,300;1,600&display=swap"
                    rel="stylesheet"
                ></link>
            </SiteHelmet>
            <GlobalStyle />
            <StyledBody>
                <StyledHeader>
                    <StyledInnerHeader trim={1}>
                        <StyledLogo>
                            <a href={prefixedPath}>
                                <StaticImage
                                    src="../../images/logo.png"
                                    alt="Logo FC Oberrieden"
                                    placeholder={'none'}
                                    width={110}
                                />
                            </a>
                        </StyledLogo>
                        <Navigation currentUrl={path} navLinks={navLinks} />
                    </StyledInnerHeader>
                </StyledHeader>
                <StyledMain>{children}</StyledMain>
                <Footer currentUrl={path} pageMeta={navData.meta} />
            </StyledBody>
        </>
    );
};

import styled from 'styled-components';
import { theme } from './theme';

export const TwoToOneGrid = styled.div`
    display: flex;
    gap: ${theme.space[1]};

     & > *:first-child {
        width: 66.6666%;
    }  

    & > *:last-child {
        width: 33.3333%;
    }

    & > *:only-child {
        width: 100%;
    }

    @media (max-width: ${theme.breakpoints[1]}rem) {
        flex-direction: column;
        
        & > *:first-child, 
        & > *:last-child {
            width: 100%;
        }
    }
`;

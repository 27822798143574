import { theme } from '@theme';
import styled from 'styled-components';

export const StyledSection = styled.section`
    padding-top: ${theme.space[2]};

    &:last-child {
        padding-bottom: ${theme.space[2]};
    }
`;
